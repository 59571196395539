@tailwind base;

@tailwind components;

@tailwind utilities;
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

html {
  font-size: 16px;
}

/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 324.98px) {
  html {
    font-size: 12px;
  }
}

/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  html {
    font-size: 14px;
  }
}

/* // Small devices (landscape phones, less than 768px) */
/* @media (max-width: 767.98px) {
  html {
    font-size: 14px;
  }
} */
